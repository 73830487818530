import _ from 'lodash'
export default function routeTemplate(temp) {
  return (data) => {
    var r = {}
    for (var i in temp) {
      if (typeof temp[i] == 'string') {
        if (temp[i].indexOf('%') == 0) {
          var key = temp[i].slice('%'.length, temp[i].length)
          console.log(key)
          r[i] = replacer(key, data)
        } else {
          r[i] = temp[i]
        }
      } else {
        r[i] = routeTemplate(temp[i])(data)
      }
    }
    return r
  }
}
function replacer(key, val) {
  return _.get(val, key)
} 
